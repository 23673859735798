import { FC } from "react";
import { TaskStatus } from "../types";
import { translateTaskStatus } from "../utils/translate-task-status";

export const HumanizeType = ({type}) => (
  <span className="text-capitalize text-nowrap">{type && type.replaceAll("_", " ")}</span>
);

type HumanizeTaskTypeProps = {type: TaskStatus};
export const HumanizeTaskType: FC<HumanizeTaskTypeProps> = ({type}) => (
  <HumanizeType type={translateTaskStatus(type)}/>
);