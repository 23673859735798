import useRailsToast from "@/components/utils/use-rails-toast";
import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import 'rrweb-player/dist/style.css';
import rrwebPlayer from "rrweb-player";

const SessionShow = () => {
  const { getToken } = useAuth();
  const { sessionId } = useParams();
  const railsToast = useRailsToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>();
  const [iframeUrl, setIframeUrl] = useState<string>();

  useEffect(() => { getSession(); }, []);

  const getSession = async () => {
    if (sessionId && !iframeUrl) {
      setLoading(true);
      const accessToken = await getToken();
      const response = await fetch(`/api/v1/sessions/${sessionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        setLoading(false);
        const json = await response.json();
        if (json.url) {
          setIframeUrl(json.url);
        } else {
          new rrwebPlayer({
            target: document.getElementById('player'),
            props: {
              autoPlay: false,
              events: JSON.parse(json?.recording || '[]'),
              skipInactive: true
            }
          });
          // console.log(json.recording);
        }
        setTaskId(json.taskId);
      } else {
        navigate('/shipments');
      }
    }
  }

  const stopSession = async () => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/sessions/${sessionId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      navigate(`/tasks/${taskId}`);
    } else {
      railsToast(await response.json(), response.status);
    }
  };

  if (loading) {
    return (
    <div className="text-center">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    );
  }
  else
    return (
      <div>
        <div id="player"></div>
        {iframeUrl ? <>
          <Button className="mb-2" onClick={stopSession}>
            Stop Session
          </Button>
          <iframe
            src={iframeUrl}
            sandbox="allow-same-origin allow-scripts"
            allow="clipboard-read; clipboard-write"
            style={{ width: '100%', aspectRatio: '13/9', border: 'none' }}
          />
        </> : <></>}
      </div>
    );
};

export default SessionShow;