import { TaskStatus } from "../types";

export const translateTaskStatus = (status: TaskStatus) => {
    // TODO: this is a poor man's i18n; we should fix our translation site-wide
    switch (status) {
      case TaskStatus.SUGGESTED:
        return 'Needs Appointment';
      case TaskStatus.IN_PROGRESS:
        return 'Appointment Requested';
      case TaskStatus.ESCALATED:
        return 'Escalated';
      case TaskStatus.AWAITING_SYNC:
        return 'Needs Confirmation'
      case TaskStatus.COMPLETED:
        return 'Completed'
      case TaskStatus.CLOSED:
        return 'Closed Outside Hubflow';
      default:
        return status
    }
  }
  