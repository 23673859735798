import { TaskStatus } from "../types";
import { Badge } from "react-bootstrap";
import { HumanizeTaskType } from "./humanize-types";

export const getStatusClass = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.SUGGESTED:
      return 'warning';
    case TaskStatus.IN_PROGRESS:
      return 'info';
    case TaskStatus.ESCALATED:
      return 'danger';
    case TaskStatus.AWAITING_SYNC:
      return 'primary'
    case TaskStatus.COMPLETED:
    case TaskStatus.CLOSED:
      return 'success';
    default:
      return'secondary';
  }
}

const TaskStatusPill = ({ status, className } : { status: TaskStatus, className?: string }) => {
  return (<Badge bg={getStatusClass(status)} className={className?? ''}><HumanizeTaskType type={status} /></Badge>);
};

export default TaskStatusPill;
