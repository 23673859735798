import { HumanizeTaskType } from "./humanize-types";
import { TaskType } from "../types";
import { Badge } from "react-bootstrap";
const TaskTypePill = ({ taskType } : { taskType: TaskType }) => {
  let bgClass;
  switch (taskType) {
    case TaskType.SCHEDULING:
      bgClass = 'info';
      break;
    case TaskType.EMAIL:
        bgClass = 'warning';
        break;
    default:
      bgClass = 'secondary';
  }
  return (<Badge bg={bgClass}><HumanizeTaskType type={taskType} /></Badge>);
};

export default TaskTypePill;
